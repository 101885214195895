.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

    .h1 {
        color: var(--color-primary);
    }
}
